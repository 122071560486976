define("backalley/pods/components/collapsible/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eHatLqLs",
    "block": "{\"symbols\":[\"@content\",\"&default\",\"@namedBlocksInfo\",\"@label\",\"&attrs\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"collapsible \",[28,\"if\",[[23,0,[\"isOpen\"]],\"collapsible--open\"],null]]]],[13,5],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"collapsible__label\"],[3,\"on\",[\"click\",[23,0,[\"toggle\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"-has-block\",[[23,3,[]],\"label\",false],null]],null,{\"statements\":[[0,\"      \"],[14,2,[[28,\"-named-block-invocation\",[\"label\"],null],[28,\"hash\",null,[[\"isOpen\"],[[23,0,[\"isOpen\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,4,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n  \"],[5,\"liquid-spacer\",[],[[\"@growDuration\",\"@growWidth\"],[[23,0,[\"growDuration\"]],false]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"isOpen\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"collapsible__content\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"-has-block\",[[23,3,[]],\"content\",false],null]],null,{\"statements\":[[0,\"          \"],[14,2,[[28,\"-named-block-invocation\",[\"content\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/collapsible/template.hbs"
    }
  });

  _exports.default = _default;
});