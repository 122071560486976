define("backalley/pods/components/shipping-group-configuration-raw-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N+qFzTdz",
    "block": "{\"symbols\":[\"@isEditable\",\"&default\"],\"statements\":[[5,\"textarea\",[[12,\"style\",\"font-family: monospace; width: 100%; min-height: 300px; overflow: scroll;\"]],[[\"@value\",\"@wrap\"],[[23,0,[\"rawData\"]],\"off\"]]],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[5,\"form-button\",[],[[\"@action\",\"@customClass\",\"@title\"],[[28,\"perform\",[[23,0,[\"acceptChanges\"]]],null],\"button-primary action__accept\",\"Save these raw changes back over the SGC\"]],{\"statements\":[[0,\"\\n    \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"check\"]]],[0,\" Accept Changes\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"form-button\",[],[[\"@action\",\"@customClass\",\"@title\"],[[28,\"perform\",[[23,0,[\"tidyFormatting\"]]],null],\"button-secondary action__tidy\",\"Tidy Formatting for ease of reading\"]],{\"statements\":[[0,\"\\n    \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"recycle\"]]],[0,\" Tidy Formatting\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"import-csv\",[],[[\"@class\",\"@delimiter\",\"@onError\",\"@onComplete\"],[\"button-upload-csv\",\",\",[28,\"action\",[[23,0,[]],[23,0,[\"onCSVImportFail\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"onCSVImportCompleted\"]]],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"upload\"]]],[0,\" Upload CSV\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"copy-button\",[],[[\"@clipboardText\",\"@class\",\"@success\",\"@error\"],[[23,0,[\"rawData\"]],\"button button-primary\",[28,\"fn\",[[23,0,[\"onCopySuccess\"]]],null],[28,\"fn\",[[23,0,[\"onCopyFail\"]]],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"clipboard\"]]],[0,\" Copy Raw to Clipboard\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/shipping-group-configuration-raw-editor/template.hbs"
    }
  });

  _exports.default = _default;
});