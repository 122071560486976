define("backalley/pods/components/box-pair-editable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xpC0A/xN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"box-pair is-editable\"],[8],[0,\"\\n  \"],[7,\"p\",false],[12,\"class\",\"box-pair-key\"],[3,\"action\",[[23,0,[]],\"edit\"]],[8],[1,[22,\"key\"],false],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"box-pair-value\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"editing\"]]],null,{\"statements\":[[0,\"      \"],[7,\"form\",true],[10,\"class\",\"box-pair-form\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"form-text\"],[8],[0,\"\\n          \"],[7,\"input\",true],[10,\"class\",\"input\"],[11,\"value\",[22,\"_val\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"change\"],null]],[11,\"type\",[22,\"type\"]],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[4,\"form-button\",null,[[\"action\"],[[28,\"action\",[[23,0,[]],\"save\"],null]]],{\"statements\":[[0,\"Save\"]],\"parameters\":[]},null],[0,\"\\n        \"],[4,\"form-button\",null,[[\"customClass\",\"action\"],[\"button-secondary\",[28,\"action\",[[23,0,[]],\"cancel\"],null]]],{\"statements\":[[0,\"Cancel\"]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"valueCurrency\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",false],[12,\"class\",\"box-pair-static\"],[3,\"action\",[[23,0,[]],\"edit\"]],[8],[1,[28,\"format-price\",[[24,[\"value\"]],[24,[\"valueCurrency\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"span\",false],[12,\"class\",\"box-pair-static\"],[3,\"action\",[[23,0,[]],\"edit\"]],[8],[1,[22,\"valuePrepend\"],false],[14,1],[1,[22,\"valueAppend\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/box-pair-editable/template.hbs"
    }
  });

  _exports.default = _default;
});