define("backalley/pods/main/paths-and-lanes/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lKim5IUk",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Paths and Lanes\"],null],false],[0,\"\\n\"],[1,[28,\"page-title\",[\"New\"],null],false],[0,\"\\n\\n\"],[5,\"paths-and-lanes/new\",[],[[],[]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/paths-and-lanes/new/template.hbs"
    }
  });

  _exports.default = _default;
});