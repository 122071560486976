define("backalley/pods/main/user-management/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CVs7aJTB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-heading\",null,[[\"fa\"],[\"users\"]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"main.user_management\"]],{\"statements\":[[0,\"Staff access\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    New user\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"notion-help\",[],[[\"@url\"],[\"https://www.notion.so/trouva/Onboarding-process-21818d7dedb14f4eb41e5f51ce1d68f0\"]]],[0,\"\\n\\n\"],[1,[28,\"page-title\",[\"Staff access\"],null],false],[0,\"\\n\"],[1,[28,\"page-title\",[\"New\"],null],false],[0,\"\\n\\n\"],[1,[28,\"user-form\",null,[[\"user\",\"onSave\"],[[24,[\"model\",\"user\"]],[28,\"route-action\",[\"onSave\"],null]]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/user-management/new/template.hbs"
    }
  });

  _exports.default = _default;
});