define("backalley/pods/components/feed-sync-permanent-rule-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SzfKNiQb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[23,0,[\"styleNamespace\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"feed-sync-permanent-rule-settings\"],[8],[0,\"\\n    \"],[5,\"textarea\",[],[[\"@spellcheck\",\"@value\"],[false,[23,0,[\"permanentRulesAsString\"]]]]],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"feed-sync-permanent-rule-settings__presets\"],[8],[0,\"\\n      \"],[7,\"button\",false],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"triggerPreset\"]],\"format\"],null]]],[8],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"bolt\"]]],[0,\" Format\"],[9],[0,\"\\n      \"],[7,\"button\",false],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"triggerPreset\"]],\"add-example\"],null]]],[8],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"plus\"]]],[0,\" Add example\"],[9],[0,\"\\n      \"],[7,\"button\",false],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"triggerPreset\"]],\"pop\"],null]]],[8],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"minus\"]]],[0,\" Remove Last Rule\"],[9],[0,\"\\n      \"],[7,\"button\",false],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"triggerPreset\"]],\"clear\"],null]]],[8],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"trash-alt\"]]],[0,\" Clear\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/feed-sync-permanent-rule-settings/template.hbs"
    }
  });

  _exports.default = _default;
});