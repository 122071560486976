define("backalley/pods/components/basic-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6he4lvBW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"list\",\"new\",\"comments\"],[[28,\"component\",[[24,[\"listComponent\"]]],[[\"notes\",\"comments\"],[[28,\"readonly\",[[24,[\"publicAPI\"]]],null],[28,\"readonly\",[[24,[\"_comments\"]]],null]]]],[28,\"component\",[[24,[\"newComponent\"]]],[[\"notes\"],[[28,\"readonly\",[[24,[\"publicAPI\"]]],null]]]],[28,\"readonly\",[[24,[\"_comments\"]]],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/basic-notes/template.hbs"
    }
  });

  _exports.default = _default;
});