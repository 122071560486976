define("backalley/pods/components/box-pair/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mLNsgNDP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"box-pair\"],[8],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"box-pair-key\"],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[28,\"hash\",null,[[\"key\"],[[28,\"component\",[\"empty-template\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"key\"]]],null,{\"statements\":[[0,\"      \"],[1,[22,\"key\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[10,\"class\",\"box-pair-value\"],[11,\"title\",[22,\"hoverText\"]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[28,\"hash\",null,[[\"value\"],[[28,\"component\",[\"empty-template\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"valueCurrency\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"format-price\",[[24,[\"value\"]],[24,[\"valueCurrency\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,\"valuePrepend\"],false],[1,[22,\"value\"],false],[1,[22,\"valueAppend\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/box-pair/template.hbs"
    }
  });

  _exports.default = _default;
});