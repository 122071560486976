define("backalley/models/product", ["exports", "backalley/mixins/request", "joi", "backalley/config/environment", "backalley/util/models", "backalley/util/stock-line", "backalley/enums/fieldExcludedFromAutomation"], function (_exports, _request, _joi, _environment, _models, _stockLine, _fieldExcludedFromAutomation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const GBP_ISO = 'GBP';
  const validations = {
    _id: _joi.default.string().required(),
    name: _joi.default.string().required(),
    description: _joi.default.string().allow('').allow(null).optional(),
    details: _joi.default.string().allow('').allow(null).optional(),
    thumbnail: _joi.default.object().allow(null),
    images: _joi.default.array().items(_joi.default.object()),
    categories: _joi.default.array().items(_joi.default.string()),
    hs_code: _joi.default.object({
      default: _joi.default.string().allow(null),
      custom: _joi.default.string().allow(null)
    }),
    vat_rate: _joi.default.number().min(0).max(100).allow('', null).optional(),
    standard_manufacturer: _joi.default.string().allow(null).optional(),
    colors: _joi.default.array().items(_joi.default.string()),
    tags: _joi.default.array().items(_joi.default.string()),
    qs: _joi.default.number().min(0).max(100),
    gtin: _joi.default.string().allow('').allow(null).optional(),
    gtin_unavailable: _joi.default.boolean(),
    published: _joi.default.boolean(),
    featured: _joi.default.boolean(),
    unsuitable: _joi.default.boolean(),
    unsuitable_for_trouva: _joi.default.boolean(),
    bought_via_curate: _joi.default.boolean(),
    boutiques: _joi.default.array(),
    sizes: _joi.default.array(),
    stock_lines: _joi.default.array(),
    shipping_policy: _joi.default.string().allow(null).valid('normal', 'unshippable', 'uk_only', 'eu_incl_uk_only'),
    weight_bracket: _joi.default.string().allow(null),
    local_price: _joi.default.object(),
    shipping_cost_addition: _joi.default.object(),
    delivery_tags: _joi.default.array().items(_joi.default.object({
      label: _joi.default.string(),
      type: _joi.default.string(),
      _id: _joi.default.string()
    })),
    ups_access_point_eligible: _joi.default.boolean(),
    fields_excluded_from_automation: _joi.default.array().items(_joi.default.object({
      field_name: _joi.default.string().valid(...Object.values(_fieldExcludedFromAutomation.default))
    })).allow(null)
  };

  function warnIfNotProduction(message) {
    if (_environment.default.environment !== 'production') {
      console.warn(message);
    }
  }

  const Product = Ember.Object.extend(_request.default, {
    boutiques: Ember.computed({
      get() {
        warnIfNotProduction('Tried to access the product.boutiques array. This is no longer recommended and should be replaced with stock_lines');
        return this.__boutiques_array;
      },

      set(_, value) {
        warnIfNotProduction('Tried to set a value on the product.boutiques array. This is no longer recommended and should be replaced with stock_lines');
        this.set('__boutiques_array', value);
        return value;
      }

    }).volatile(),

    /**
    * Update a dimension attribute on the stock lines
    *
    * @param {boolean} sales   - The stock dimension to update
    * @returns {object[]}      - Updated stock lines
    */
    updatedStockLineDimension(dimension, value) {
      (false && !(['width', 'length', 'height'].includes(dimension)) && Ember.assert(`Product model: Unknown dimension ${dimension}`, ['width', 'length', 'height'].includes(dimension)));
      return this.updateAttributeOnAllStockLines(`dimensions.${dimension}`, value);
    },

    /**
     * Update care instructions on all stock lines until we get around to handling
     * the care instructions for each variation
     *
     * @param {string} value - Instructions of how to care for the product
     */
    updateCareInstructions(value) {
      return this.updateAttributeOnAllStockLines('care_instructions', value);
    },

    updateDimensions(value) {
      return this.updateAttributeOnAllStockLines('dimensions', value);
    },

    /**
     * Updates an attribute on each stock line with a given value
     *
     * @param {string} attribute    - Attribute to update on all stock lines
     * @param {*} value             - Value to assign to every stock line
     * @returns {object[]}          - Updated stock lines
     */
    updateAttributeOnAllStockLines(attribute, value) {
      this.stock_lines.forEach(stockLine => {
        Ember.set(stockLine, attribute, value);
      });
      return this.stock_lines;
    },

    /**
     * Create new product
     * @return {Object} Promise
     */
    post() {
      var product = this;
      return this.validate().then(product => {
        return this.request({
          method: 'POST',
          url: this.apiURL('products'),
          data: JSON.stringify(product)
        });
      }).then(res => {
        const data = res.products[0];
        product.setProperties(data);
      });
    },

    /**
     * Save product
     * @return {Object} Promise
     */
    save(extraData = {}) {
      return this.validate().then(data => {
        let changes = (0, _models.getChanges)(this._orig, data, Object.keys(validations)); // Note: need to send sizes to be able to override boutiques....

        if (changes.boutiques && this._orig.sizes) {
          changes.sizes = this._orig.sizes;
        } // Add the version field, to avoid overriding with stale data


        changes.__v = this._orig._v;
        return changes;
      }).then(data => {
        return _objectSpread(_objectSpread({}, data), {}, {
          // Inject stock_lines array
          stock_lines: (0, _stockLine.stockLinesWithoutComputedSize)(this.stock_lines)
        });
      }).then(product => {
        return this.request({
          method: 'PUT',
          url: this.apiURL(`products/${product._id}`),
          data: JSON.stringify(_objectSpread(_objectSpread({}, product), extraData)),
          headers: {
            'x-request-origin': 'backalley'
          }
        });
      }).then(res => {
        const data = res.products[0];
        this.setOriginalData(data);
        this.setProperties(data);
        return res;
      });
    },

    /**
     * Remove feed attributes from a product, saving it
     * @return {Object} Promise
     */
    detachFromFeed() {
      return this.request({
        method: 'POST',
        url: this.apiURL(`products/${this._id}/detach-from-feed`),
        data: JSON.stringify({
          sync_overwritten: true
        })
      }).then(res => {
        const data = res.products[0]; // Feed Sync properties should have been removed. Since they no longer exist in
        // the product, they won't be updated in the model unless we set them to undefined

        data.sync_provider = res.products[0].sync_provider;
        data.sync_ref_id = res.products[0].sync_ref_id;
        data.sync_remote_id = res.products[0].sync_remote_id;
        this.setOriginalData(data);
        this.setProperties(data);
      });
    },

    validate() {
      let data = JSON.parse(JSON.stringify(this));
      this.set('errors', {});
      return (0, _models.validateSchema)(Product.schema, data).catch(({
        label,
        message
      }) => {
        this.set(`errors.${label}`, message);
        throw new Error(message);
      });
    },

    // @TODO set as a computed property for now, just in case
    // we need to handle undefined states. Computing based on
    // price, until we can get an actual source.
    inputCurrency: Ember.computed('price', function () {
      return GBP_ISO;
    }),
    hasImages: Ember.computed.gt('images.length', 0),
    // Checks if the product has sizes
    hasSizes: Ember.computed('stock_lines.@each._id', function () {
      return (0, _stockLine.hasSizes)(this.stock_lines);
    }),
    isInStock: Ember.computed('stock_lines.@each._id', function () {
      return (0, _stockLine.isInStock)(this.stock_lines);
    }),
    isOnSale: Ember.computed('stock_lines.@each.sales', function () {
      return (0, _stockLine.isOnSale)(this.stock_lines);
    }),
    stockLinesInStock: Ember.computed('stock_lines.@each.quantity', function () {
      return this.stock_lines.filter(({
        quantity
      }) => quantity !== 0);
    }),
    publishedChanged: Ember.observer('published', function () {
      if (this.published === false) {
        Ember.set(this, 'featured', false);
      }

      if (this.published === true) {
        Ember.set(this, 'unsuitable', false);
      }
    }),
    featuredChanged: Ember.observer('featured', function () {
      if (this.featured === true) {
        Ember.setProperties(this, {
          published: true,
          unsuitable: false,
          unsuitable_for_trouva: false
        });
      }
    }),
    unsuitableChanged: Ember.observer('unsuitable', function () {
      if (this.unsuitable === true) {
        Ember.setProperties(this, {
          published: false,
          featured: false,
          unsuitable_for_trouva: false
        });
      }
    }),
    unsuitableForTrouvaChanged: Ember.observer('unsuitable_for_trouva', function () {
      if (this.unsuitable_for_trouva === true) {
        Ember.setProperties(this, {
          featured: false,
          unsuitable: false
        });
      }
    }),
    publishedDisabled: Ember.computed('unsuitable', function () {
      if (Ember.get(this, 'unsuitable')) {
        return true;
      }

      return false;
    }),
    featuredDisabled: Ember.computed('unsuitable', 'unsuitable_for_trouva', function () {
      const featuredDisabled = false || Ember.get(this, 'unsuitable') || Ember.get(this, 'unsuitable_for_trouva');
      return featuredDisabled;
    }),
    pendingDisabled: Ember.computed('unsuitable', 'unsuitable_for_trouva', 'published', 'featured', function () {
      if (Ember.get(this, 'unsuitable') || Ember.get(this, 'unsuitable_for_trouva') || Ember.get(this, 'published') && Ember.get(this, 'featured')) {
        return true;
      }

      return false;
    }),
    unsuitableDisabled: Ember.computed('published', 'featured', function () {
      return Ember.get(this, 'published') || Ember.get(this, 'featured');
    }),
    unsuitableForTrouvaDisabled: Ember.computed('published', 'featured', 'unsuitable', function () {
      return Ember.get(this, 'featured') || Ember.get(this, 'unsuitable');
    })
  });
  Product.reopen({
    init() {
      this._super(...arguments);

      const product = this; // Convert product to stock_lines if boutiques array exists

      (0, _stockLine.convertProductToStockLines)(product); // Create a copy of the product so we can compare changes done to the original object

      this.setOriginalData(product);
    },

    /**
     * Set original data to be able to check against when saving
     * @param {Object} data Data
     * @return {Object} data
    */
    setOriginalData(data) {
      this._orig = JSON.parse(JSON.stringify(data));
      return data;
    }

  });
  Product.reopenClass(_request.default, {
    // Class methods
    schema: _joi.default.object().min(1).keys(validations),

    create(data = {}) {
      let p = this._super(data);

      p.setOriginalData(data);
      return p;
    },

    bulkCreate(products = []) {
      if (Ember.isEmpty(products)) {
        return Ember.RSVP.reject('products must have at least one item');
      }

      return this.request({
        method: 'POST',
        url: this.apiURL('products'),
        data: JSON.stringify(products)
      });
    },

    /**
     * Find products
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    find(data) {
      return this.findWithMetaAndCreate(data).then(res => res.products);
    },

    /**
     * Find products and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMetaAndCreate(data) {
      return this.findWithMeta(data).then(res => {
        return {
          products: res.products.map(product => {
            return this.create(product);
          }),
          meta: res.meta
        };
      });
    },

    /**
     * Find products and returns meta too
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findWithMeta(data) {
      return this.request({
        method: 'post',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        url: this.apiURL('products'),
        data: JSON.stringify(_objectSpread(_objectSpread({}, data), {}, {
          plain: true
        }))
      });
    },

    /**
     * Find product by id
     * @param  {String} id product _id
     * @return {Object}    Promise
     */
    findById(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`products/${id}`),
        data: {
          plain: true
        }
      }).then(res => this.create(res.products[0]));
    },

    /**
     * Find products on /public route (ElasticSearch)
     * @param  {Object} data Queryparams to attach
     * @return {Promise}      Request Promise
     */
    findPublic(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('public/products'),
        data
      });
    }

  });
  var _default = Product;
  _exports.default = _default;
});