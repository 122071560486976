define("backalley/pods/components/shipment-zone-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8PENBxAI",
    "block": "{\"symbols\":[\"@shipmentZone\",\"@isEdit\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Source\"],[9],[0,\"\\n    \"],[5,\"form-text\",[],[[\"@placeholder\",\"@value\",\"@disabled\",\"@action\"],[\"e.g. PT\",[23,1,[\"source\"]],[23,2,[]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"shipmentZone\",\"source\"]]],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Destination\"],[9],[0,\"\\n    \"],[5,\"form-text\",[],[[\"@placeholder\",\"@value\",\"@disabled\",\"@action\"],[\"e.g. GB\",[23,1,[\"destination\"]],[23,2,[]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"shipmentZone\",\"destination\"]]],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"UPS Zone\"],[9],[0,\"\\n    \"],[5,\"form-text\",[],[[\"@placeholder\",\"@value\",\"@action\"],[\"e.g. 42\",[23,1,[\"upsZone\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"shipmentZone\",\"upsZone\"]]],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"form-submit\",[],[[\"@action\"],[[28,\"fn\",[[23,0,[\"save\"]]],null]]],{\"statements\":[[0,\"\\n    Save\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/shipment-zone-form/template.hbs"
    }
  });

  _exports.default = _default;
});