define("backalley/pods/main/curate/brands/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0cC+q1QU",
    "block": "{\"symbols\":[],\"statements\":[[5,\"page-heading\",[],[[\"@fa\"],[\"store\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    Curate\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.curate.brands\"]],{\"statements\":[[0,\"\\n      Brands\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[1,[24,[\"model\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[28,\"page-title\",[[24,[\"model\",\"name\"]]],null],false],[0,\"\\n\\n\"],[5,\"curate-brand-form\",[],[[\"@brand\",\"@isEdit\"],[[22,\"model\"],true]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/curate/brands/edit/template.hbs"
    }
  });

  _exports.default = _default;
});