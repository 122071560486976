define("backalley/pods/main/curate/brand-tags/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wnrGcLWB",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"page-heading\",[],[[\"@fa\"],[\"tags\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    Curate\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.curate.brand-tags\"]],{\"statements\":[[0,\"\\n      Brand Tags\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.curate.brand-tags.new\"]],{\"statements\":[[0,\"\\n      New\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[28,\"page-title\",[\"New\"],null],false],[0,\"\\n\\n\"],[5,\"curate-brand-tag-form\",[],[[\"@curateBrandTag\"],[[23,1,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/curate/brand-tags/new/template.hbs"
    }
  });

  _exports.default = _default;
});