define("backalley/pods/components/order-edit/path-detail-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+l8o8y51",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"label\"],\"statements\":[[5,\"collapsible\",[],[[\"@namedBlocksInfo\"],[[28,\"hash\",null,[[\"label\",\"content\"],[1,0]]]]],{\"statements\":[[4,\"if\",[[28,\"-is-named-block-invocation\",[[23,1,[]],\"label\"],null]],null,{\"statements\":[[4,\"let\",[[23,2,[]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"h2\",true],[8],[1,[22,\"pathTitle\"],false],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"form-note\"],[8],[0,\" (click to \"],[1,[28,\"if\",[[23,3,[\"isOpen\"]],\"close\",\"open\"],null],false],[0,\")\"],[9],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@rotation\"],[\"chevron-down\",[28,\"if\",[[23,3,[\"isOpen\"]],180,0],null]]]],[0,\"\\n  \"]],\"parameters\":[3]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"-is-named-block-invocation\",[[23,1,[]],\"content\"],null]],null,{\"statements\":[[0,\"\\n    \"],[5,\"box-pair\",[],[[\"@key\",\"@value\"],[\"Shipping was charged per\",[22,\"shippingWasChargedPer\"]]]],[0,\"\\n    \"],[5,\"box-pair\",[],[[\"@key\",\"@value\"],[\"Shipping includes free Boutiques\",[28,\"if\",[[24,[\"freeShippingUsedOnPath\"]],\"Yes\",\"No\"],null]]]],[0,\"\\n    \"],[5,\"box-pair\",[],[[\"@key\",\"@value\"],[\"Zone path\",[24,[\"firstShippingVariant\",\"zone_path_codes\"]]]]],[0,\"\\n    \"],[5,\"box-pair\",[],[[\"@key\",\"@value\"],[\"Shipping variant\",[24,[\"firstShippingVariant\",\"variant\"]]]]],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1,2]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/order-edit/path-detail-item/template.hbs"
    }
  });

  _exports.default = _default;
});