define("backalley/pods/components/landing-page/partials/share/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zOR+08Yj",
    "block": "{\"symbols\":[\"platform\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"item\",\"platforms\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n\"],[4,\"form-checkbox\",null,[[\"checked\"],[[23,1,[\"enabled\"]]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/landing-page/partials/share/template.hbs"
    }
  });

  _exports.default = _default;
});