define("backalley/pods/main/tasks/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CH+wyZmD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-heading\",null,[[\"fa\"],[\"tasks\"]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    Tasks / Update a task\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"label\",true],[10,\"class\",\"checkbox\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"model\",\"task\",\"completed\"]]]]],false],[0,\" Completed?\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"page-title\",[\"Edit task\"],null],false],[0,\"\\n\\n\"],[1,[28,\"task-form\",null,[[\"task\",\"users\",\"isEdit\",\"onSubmit\",\"onCancel\"],[[24,[\"model\",\"task\"]],[24,[\"model\",\"users\"]],true,[28,\"route-action\",[\"update\",[24,[\"model\",\"task\"]]],null],[28,\"route-action\",[\"backToTasks\"],null]]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/tasks/edit/template.hbs"
    }
  });

  _exports.default = _default;
});