define("backalley/pods/components/order-edit/path-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3YUuFzf+",
    "block": "{\"symbols\":[\"reservationsForPath\"],\"statements\":[[7,\"p\",false],[12,\"class\",\"box-view-heading\"],[3,\"action\",[[23,0,[]],[28,\"toggle\",[\"expandSection\",[23,0,[]]],null]]],[8],[0,\"\\n  \"],[7,\"a\",true],[8],[0,\"Toggle\"],[9],[0,\"\\n  Path Details\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"expandSection\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"reservationsGroupedByPath\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"order-edit/path-detail-item\",null,[[\"order\",\"reservationsForPath\"],[[24,[\"order\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/order-edit/path-detail/template.hbs"
    }
  });

  _exports.default = _default;
});