define("backalley/pods/main/publishers/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q8uwYIzK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-heading\",null,[[\"fa\"],[\"cubes\"]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"main.publishers\"]],{\"statements\":[[0,\"Publishers\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    Edit publisher\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"page-title\",[\"Publishers\"],null],false],[0,\"\\n\"],[1,[28,\"page-title\",[[24,[\"model\",\"publisher\",\"name\"]]],null],false],[0,\"\\n\\n\"],[1,[28,\"publisher-form\",null,[[\"publisher\",\"user\",\"action\"],[[24,[\"model\",\"publisher\"]],[24,[\"model\",\"user\"]],[28,\"action\",[[23,0,[]],[24,[\"model\",\"actions\",\"update\"]],[24,[\"model\",\"publisher\"]]],null]]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/publishers/edit/template.hbs"
    }
  });

  _exports.default = _default;
});