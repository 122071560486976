define("backalley/pods/components/product-delivery-tag-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n52ogVAS",
    "block": "{\"symbols\":[\"@productDeliveryTag\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Type\"],[9],[0,\"\\n    \"],[5,\"form-text\",[],[[\"@placeholder\",\"@value\",\"@action\"],[\"e.g. dropship\",[23,1,[\"type\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"productDeliveryTag\",\"type\"]]],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Label\"],[9],[0,\"\\n    \"],[5,\"form-text\",[],[[\"@placeholder\",\"@value\",\"@action\"],[\"e.g. generic\",[23,1,[\"label\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"productDeliveryTag\",\"label\"]]],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"form-submit\",[],[[\"@action\"],[[28,\"fn\",[[23,0,[\"save\"]]],null]]],{\"statements\":[[0,\"\\n    Save\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/product-delivery-tag-form/template.hbs"
    }
  });

  _exports.default = _default;
});