define("backalley/pods/components/packaging-category-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lz3Q+tf0",
    "block": "{\"symbols\":[\"@category\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Name\"],[9],[0,\"\\n    \"],[5,\"form-text\",[],[[\"@value\",\"@action\"],[[23,1,[\"name\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,1,[\"name\"]]],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n  \"],[5,\"form-submit\",[],[[\"@action\"],[[28,\"action\",[[23,0,[]],\"save\"],null]]],{\"statements\":[[0,\"\\n    Save category\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/packaging-category-form/template.hbs"
    }
  });

  _exports.default = _default;
});