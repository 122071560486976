define("backalley/pods/components/order-edit/order-ups-access-point/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cuHVhST+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[10,\"class\",\"box-view-heading\"],[8],[0,\"\\n  UPS Access Point\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"box-pair\",null,[[\"key\",\"value\"],[\"Access Point Id\",[24,[\"upsAccessPoint\",\"id\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"box-pair\",null,[[\"key\",\"value\"],[\"Name\",[24,[\"upsAccessPoint\",\"name\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"box-pair\",null,[[\"key\",\"value\"],[\"Address\",[24,[\"upsAccessPoint\",\"address_line_1\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"box-pair\",null,[[\"key\",\"value\"],[\"Postal Code\",[24,[\"upsAccessPoint\",\"postal_code\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"box-pair\",null,[[\"key\",\"value\"],[\"Country\",[24,[\"countryById\",\"weengs_name\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/order-edit/order-ups-access-point/template.hbs"
    }
  });

  _exports.default = _default;
});