define("backalley/pods/main/boutique-categories/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ihv0I5Mn",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-heading\",null,[[\"fa\"],[\"icon-list\"]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"main.boutique_categories\"]],{\"statements\":[[0,\"Boutique categories\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    New boutique category\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"page-title\",[\"New boutique category\"],null],false],[0,\"\\n\\n\"],[1,[28,\"forms/boutique-category\",null,[[\"boutiqueCategory\",\"onSave\"],[[24,[\"model\",\"boutiqueCategory\"]],[28,\"route-action\",[\"save\"],null]]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/boutique-categories/new/template.hbs"
    }
  });

  _exports.default = _default;
});