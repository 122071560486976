define("backalley/pods/components/delighted-customer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xK/zsipC",
    "block": "{\"symbols\":[\"response\"],\"statements\":[[4,\"if\",[[24,[\"_loading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"box-pair loader\"],[8],[0,\"\\n    \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\",\"@spin\"],[\"spinner\",true]]],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Loading NPS Score\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"responses\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"box-pair\",null,[[\"key\",\"value\"],[\"NPS ⭐️\",[23,1,[\"score\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[23,1,[\"comment\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"box-pair\",null,[[\"key\",\"value\"],[\"NPS Comment\",[23,1,[\"comment\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/delighted-customer/template.hbs"
    }
  });

  _exports.default = _default;
});