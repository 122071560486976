define("backalley/pods/main/shipping-group-configurations/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wVEIHeua",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"scroll-positioner\"],[8],[0,\"\\n\\n  \"],[5,\"page-heading\",[],[[\"@fa\"],[\"file-alt\"]],{\"statements\":[[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"main.shipping-group-configurations.index\"]],{\"statements\":[[0,\"\\n        Shipping Group Configurations\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n      \"],[4,\"if\",[[24,[\"model\",\"sgc\",\"name\"]]],null,{\"statements\":[[1,[24,[\"model\",\"sgc\",\"name\"]],false]],\"parameters\":[]},{\"statements\":[[0,\"New\"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[1,[28,\"page-title\",[\"New\"],null],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"page-error-block\"],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"exclamation-triangle\"]]],[0,\" \"],[1,[24,[\"model\",\"error\"]],false],[9],[0,\"\\n      \"],[7,\"ul\",true],[10,\"class\",\"error-suggestions\"],[8],[0,\"\\n        \"],[7,\"li\",true],[8],[0,\"- Promenade may be experiencing issues.\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"shipping-group-configuration-form\",[],[[\"@shippingPaths\",\"@sgc\",\"@formType\"],[[23,0,[\"model\",\"shippingPaths\"]],[23,0,[\"model\",\"sgc\"]],\"create\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/shipping-group-configurations/new/template.hbs"
    }
  });

  _exports.default = _default;
});