define("backalley/pods/components/zendesk-search-ticket/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9EfwxSkg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"zendesk-ticket \",[24,[\"ticket\",\"status\"]]]]],[8],[0,\"\\n  \"],[7,\"a\",true],[11,\"href\",[28,\"if\",[[24,[\"openZendesk\"]],[24,[\"url\"]]],null]],[10,\"target\",\"_blank\"],[10,\"class\",\"clearfix ticket-link\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"ticket-status\"],[8],[1,[24,[\"ticket\",\"status\"]],false],[9],[0,\"\\n\"],[4,\"basic-tooltip\",null,[[\"hasQuestionMark\",\"hasTitle\",\"title\",\"titleClass\",\"classNames\"],[false,true,[24,[\"ticket\",\"subject\"]],\"ticket-subject\",\"tooltip-long\"]],{\"statements\":[[0,\"        \"],[7,\"strong\",true],[8],[1,[24,[\"ticket\",\"subject\"]],false],[9],[7,\"br\",true],[8],[9],[0,\"\\n        Requester name: \"],[1,[28,\"get\",[[28,\"await\",[[24,[\"requester\"]]],null],\"name\"],null],false],[7,\"br\",true],[8],[9],[0,\"\\n        Requester email: \"],[1,[28,\"get\",[[28,\"await\",[[24,[\"requester\"]]],null],\"email\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/zendesk-search-ticket/template.hbs"
    }
  });

  _exports.default = _default;
});