define("backalley/pods/components/form-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+1f5A4rX",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[5,\"power-select\",[],[[\"@options\",\"@selected\",\"@onChange\",\"@placeholder\"],[[22,\"content\"],[22,\"_value\"],[28,\"action\",[[23,0,[]],\"onSelect\"],null],[22,\"placeholder\"]]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"get\",[[23,1,[]],[24,[\"labelPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/form-select/template.hbs"
    }
  });

  _exports.default = _default;
});