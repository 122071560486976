define("backalley/pods/main/manual-adjustments/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xf0YKPsb",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-heading\",null,[[\"fa\"],[\"map-marker-alt\"]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"main.manual_adjustments\"]],{\"statements\":[[0,\"Manual Adjustments\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    Edit\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"manual-adjustment-edit\",[],[[\"@adjustment\",\"@onComplete\",\"@boutiqueName\",\"@productId\",\"@order\"],[[24,[\"model\",\"adjustment\"]],[28,\"route-action\",[\"onCompleteAdjustmentUpdate\"],null],[24,[\"model\",\"adjustment\",\"boutique\",\"name\"]],[24,[\"model\",\"adjustment\",\"reservation\",\"product\"]],[24,[\"model\",\"adjustment\",\"order\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/manual-adjustments/edit/template.hbs"
    }
  });

  _exports.default = _default;
});