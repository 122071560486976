define("backalley/pods/main/boutique-restock-information/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ELKzug4j",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"page-heading\",[],[[\"@fa\"],[\"stopwatch\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.boutique-restock-information\"]],{\"statements\":[[0,\"\\n      Boutique restock information\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.boutique-restock-information.new\"]],{\"statements\":[[0,\"\\n      New\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[28,\"page-title\",[\"New\"],null],false],[0,\"\\n\\n\"],[5,\"boutique-restock-information-form\",[],[[\"@boutiqueRestockInformation\"],[[23,1,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/boutique-restock-information/new/template.hbs"
    }
  });

  _exports.default = _default;
});