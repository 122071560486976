define("backalley/pods/components/import-csv/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3330AUPk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"uploading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"box-pair loader\"],[8],[0,\"\\n    \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\",\"@spin\"],[\"spinner\",true]]],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Uploading .csv\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"upload\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"id\",\"accept\",\"class\",\"change\"],[\"file\",[28,\"concat\",[[24,[\"elementId\"]],\"-name\"],null],\".csv\",\"inputfile\",[28,\"action\",[[23,0,[]],\"importCSV\"],null]]]],false],[0,\"\\n    \"],[7,\"label\",true],[11,\"for\",[28,\"concat\",[[24,[\"elementId\"]],\"-name\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Choose a file\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"uploadStatus\"]],\"success\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"Upload of the CSV completed \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"check-circle\"]]],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"uploadStatus\"]],\"fail\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"Upload of the CSV has Failed \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"times-circle\"]]],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/import-csv/template.hbs"
    }
  });

  _exports.default = _default;
});