define("backalley/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IfcWMFGT",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Trouva\"],null],false],[0,\"\\n\\n\"],[1,[22,\"head-layout\"],false],[0,\"\\n\"],[1,[22,\"notify-popup\"],false],[0,\"\\n\"],[1,[22,\"iframe-preview\"],false],[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"site-container \",[28,\"if\",[[24,[\"globals\",\"displayGlobalSearch\"]],\"no-scroll\"],null]]]],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});