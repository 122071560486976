define("backalley/pods/components/price-with-currency/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wH0iXlE3",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[24,[\"disabled\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"format-price\",[[24,[\"amount\"]],[24,[\"currency\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"input\",[],[[\"@class\",\"@type\",\"@value\",\"@input\"],[\"input\",\"number\",[22,\"amountInPences\"],[28,\"action\",[[23,0,[]],\"amountChanged\"],null]]]],[0,\"\\n\\n  \"],[5,\"power-select\",[],[[\"@placeholder\",\"@options\",\"@onChange\"],[[22,\"currency\"],[22,\"currencyOptions\"],[28,\"action\",[[23,0,[]],\"selectCurrency\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[23,1,[\"iso_4217\"]],false],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/price-with-currency/template.hbs"
    }
  });

  _exports.default = _default;
});