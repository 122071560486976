define("backalley/pods/components/order-edit/manual-adjustment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GWzEG6zJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[10,\"class\",\"box-view-heading\"],[8],[0,\"Pre-Fill Manual Adjustment Form\"],[9],[0,\"\\n\\n\"],[1,[28,\"create-manual-adjustment-form\",null,[[\"order\",\"reservations\",\"products\",\"boutiques\",\"adjustmentConfig\",\"adjustments\",\"onCopyFormUrl\"],[[24,[\"order\"]],[24,[\"reservations\"]],[24,[\"products\"]],[24,[\"boutiques\"]],[24,[\"adjustmentConfig\"]],[24,[\"adjustments\"]],[28,\"action\",[[23,0,[]],\"onCopyURL\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/order-edit/manual-adjustment/template.hbs"
    }
  });

  _exports.default = _default;
});