define("backalley/pods/components/order-edit/payment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o/L8U81O",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[10,\"class\",\"box-view-heading\"],[8],[0,\"Payment\"],[9],[0,\"\\n\\n\"],[7,\"a\",true],[11,\"href\",[22,\"transactionUrl\"]],[10,\"target\",\"_blank\"],[8],[0,\"\\n  \"],[1,[28,\"box-pair\",null,[[\"key\",\"value\"],[\"Payment channel\",[24,[\"paymentChannel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"a\",true],[11,\"href\",[22,\"transactionUrl\"]],[10,\"target\",\"_blank\"],[8],[0,\"\\n  \"],[1,[28,\"box-pair\",null,[[\"key\",\"value\"],[\"Transaction ID\",[24,[\"transactionId\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"box-pair\",null,[[\"key\",\"value\"],[\"Payment type\",[24,[\"paymentType\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"order\",\"payment_channel\"]],\"braintree\"],null]],null,{\"statements\":[[4,\"if\",[[24,[\"_showCustomerID\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"box-pair\",null,[[\"key\",\"value\"],[\"Braintree customer ID\",[28,\"if\",[[24,[\"order\",\"user\",\"braintree_customer_id\"]],[24,[\"order\",\"user\",\"braintree_customer_id\"]],\"N/A\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"class\",\"box-pair\"],[3,\"action\",[[23,0,[]],[28,\"toggle\",[\"_showCustomerID\",[23,0,[]]],null]]],[8],[0,\"\\n      Need the Customer ID for Braintree?\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/order-edit/payment/template.hbs"
    }
  });

  _exports.default = _default;
});