define("backalley/pods/components/page-heading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "diU9l/H4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[[22,\"fa\"]]]],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/page-heading/template.hbs"
    }
  });

  _exports.default = _default;
});