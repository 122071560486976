define("backalley/pods/components/site-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "taXGEmUk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\",true],[10,\"id\",\"site-toggle\"],[10,\"class\",\"site-toggle\"],[8],[0,\"\\n  \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"bars\"]]],[0,\"\\n  \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"times\"]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[10,\"class\",\"site-title\"],[8],[0,\"Backalley\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/site-header/template.hbs"
    }
  });

  _exports.default = _default;
});