define("backalley/pods/components/order-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lAsVSgZ6",
    "block": "{\"symbols\":[\"notes\",\"&default\"],\"statements\":[[4,\"basic-notes\",null,[[\"title\",\"resource\",\"item\",\"requesters\",\"order\",\"products\",\"boutiques\",\"reservations\",\"newComponent\"],[\"Notes\",[24,[\"resource\"]],[24,[\"item\"]],[24,[\"requesters\"]],[24,[\"order\"]],[24,[\"products\"]],[24,[\"boutiques\"]],[24,[\"reservations\"]],[24,[\"newComponent\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"array-contains\",[[23,1,[\"comments\"]],\"text\",\"VIP\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"banner vip\"],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\",\"@pulse\"],[\"exclamation-triangle\",true]]],[0,\" This is a VIP customer\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"array-contains\",[[23,1,[\"comments\"]],\"text\",\"FRAUD\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"banner fraud\"],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\",\"@pulse\"],[\"exclamation-triangle\",true]]],[0,\" This is a fraud order\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"expanded\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"new\"]],\"expected `notes.new` to be a contextual component but found a string. Did you mean `(component notes.new)`? ('backalley/pods/components/order-notes/template.hbs' @ L25:C6) \"],null]],[[\"item\",\"requesters\",\"order\",\"products\",\"boutiques\",\"reservations\"],[[24,[\"item\"]],[24,[\"requesters\"]],[24,[\"order\"]],[24,[\"products\"]],[24,[\"boutiques\"]],[24,[\"reservations\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[23,1,[\"list\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/order-notes/template.hbs"
    }
  });

  _exports.default = _default;
});