define("backalley/pods/main/curate/products/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "atde3oSi",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"page-heading\",[],[[\"@fa\"],[\"store\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    Curate\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.curate.products\"]],{\"statements\":[[0,\"\\n      Products\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"main.curate.products.edit\",[23,1,[\"_id\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[28,\"page-title\",[[23,1,[\"name\"]]],null],false],[0,\"\\n\\n\"],[5,\"curate-product-version-two-form\",[],[[\"@product\",\"@isEdit\"],[[23,1,[]],true]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/curate/products/edit/template.hbs"
    }
  });

  _exports.default = _default;
});