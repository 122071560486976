define("backalley/pods/main/sizes/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PbwhPCeZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-heading\",null,[[\"fa\"],[\"cubes\"]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"main.sizes\"]],{\"statements\":[[0,\"Sizes\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    Edit size\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"page-title\",[\"Sizes\"],null],false],[0,\"\\n\"],[1,[28,\"page-title\",[[24,[\"model\",\"size\",\"label\"]]],null],false],[0,\"\\n\\n\"],[7,\"form\",true],[10,\"class\",\"form-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Label\"],[9],[0,\"\\n    \"],[4,\"form-text\",null,[[\"error\",\"placeholder\",\"autofocus\",\"value\",\"action\"],[[24,[\"model\",\"size\",\"errors\",\"label\"]],\"Ex: Small\",true,[24,[\"model\",\"size\",\"label\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"size\",\"label\"]]],null]],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Filter label\"],[9],[0,\"\\n    \"],[4,\"form-text\",null,[[\"error\",\"placeholder\",\"autofocus\",\"value\",\"action\"],[[24,[\"model\",\"size\",\"errors\",\"filter_label\"]],\"Ex: Small\",true,[24,[\"model\",\"size\",\"filter_label\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"size\",\"filter_label\"]]],null]],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"form-submit\",null,[[\"action\"],[[28,\"action\",[[23,0,[]],[24,[\"model\",\"actions\",\"updateSize\"]],[24,[\"model\",\"size\"]]],null]]],{\"statements\":[[0,\"    Save\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/sizes/edit/template.hbs"
    }
  });

  _exports.default = _default;
});