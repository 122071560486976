define("backalley/pods/components/zendesk-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VQb4FLQy",
    "block": "{\"symbols\":[\"ticket\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"refreshList\",\"refreshAndInsertTicket\"],[[28,\"action\",[[23,0,[]],\"refreshList\"],null],[28,\"action\",[[23,0,[]],\"refreshAndInsertTicket\"],null]]]]]],[0,\"\\n\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"_loading\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"_insertedTicket\"]]],null,{\"statements\":[[4,\"copy-button\",null,[[\"clipboardText\",\"tabindex\",\"class\"],[[24,[\"_insertedTicket\",\"id\"]],\"-1\",\"zendesk-create-ticket-success\"]],{\"statements\":[[0,\"      \"],[7,\"h3\",true],[8],[0,\"\\n        \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"check\"]]],[0,\"\\n        Ticket created\\n      \"],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"Click here to copy the ticket ID\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"hasTickets\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"tickets\"]]],null,{\"statements\":[[4,\"copy-button\",null,[[\"tagName\",\"clipboardText\",\"success\"],[\"a\",[23,1,[\"id\"]],[28,\"action\",[[23,0,[]],[24,[\"notifySuccess\"]],\"Copied Zendesk ticket ID! 👍\"],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"zendesk-search-ticket\",null,[[\"ticket\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"loader no-tickets\"],[8],[0,\"No tickets found\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"loader\"],[8],[0,\"\\n    \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\",\"@spin\"],[\"spinner\",true]]],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Loading\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/zendesk-search/template.hbs"
    }
  });

  _exports.default = _default;
});