define("backalley/pods/main/made/orders/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v56AbyY1",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[1,[28,\"page-title\",[[23,1,[\"channel_specific\",\"order_reference\"]]],null],false],[0,\"\\n\\n\"],[5,\"page-main-orders-edit\",[],[[\"@model\",\"@order\",\"@channel\"],[[23,1,[]],[23,1,[\"order\"]],[23,0,[\"channel\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/made/orders/edit/template.hbs"
    }
  });

  _exports.default = _default;
});