define("backalley/pods/main/frames/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e5ZCsgwS",
    "block": "{\"symbols\":[],\"statements\":[[5,\"page-heading\",[],[[\"@fa\"],[\"store\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.frames.index\"]],{\"statements\":[[0,\"\\n      Frames\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[1,[24,[\"model\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"frame-form\",[],[[\"@frame\",\"@isEdit\"],[[23,0,[\"model\"]],false]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/frames/new/template.hbs"
    }
  });

  _exports.default = _default;
});