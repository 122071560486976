define("backalley/pods/components/notion-help/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "01dCEPzP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[10,\"target\",\"_blank\"],[11,\"href\",[22,\"url\"]],[10,\"rel\",\"noopener\"],[8],[0,\"\\n  \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"external-link-alt\"]]],[0,\"\\n  Need help?\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/notion-help/template.hbs"
    }
  });

  _exports.default = _default;
});