define("backalley/pods/components/frame-tag-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DSyt1G8P",
    "block": "{\"symbols\":[\"option\",\"@frameTag\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-container frame-tag-form\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Name\"],[9],[0,\"\\n    \"],[5,\"form-text\",[],[[\"@value\",\"@action\"],[[23,2,[\"name\"]],[28,\"fn\",[[28,\"mut\",[[23,2,[\"name\"]]],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Type\"],[9],[0,\"\\n    \"],[5,\"power-select\",[],[[\"@selected\",\"@options\",\"@onChange\"],[[23,2,[\"type\"]],[23,0,[\"tagOptions\"]],[28,\"fn\",[[28,\"mut\",[[23,2,[\"type\"]]],null]],null]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"humanize\",[[23,1,[]]],null],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Image (optional)\"],[9],[0,\"\\n\"],[4,\"if\",[[23,2,[\"image\",\"url\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\",true],[11,\"src\",[23,2,[\"image\",\"url\"]]],[11,\"alt\",[23,2,[\"name\"]]],[10,\"width\",\"120\"],[10,\"height\",\"120\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      No image uploaded.\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Upload image\"],[9],[0,\"\\n    \"],[5,\"form-cloudinary\",[],[[\"@folder\",\"@onUpload\"],[[23,0,[\"imageFolder\"]],[23,0,[\"setImageUrl\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n  \"],[5,\"form-submit\",[],[[\"@action\"],[[23,0,[\"save\"]]]],{\"statements\":[[0,\"\\n    Save\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/frame-tag-form/template.hbs"
    }
  });

  _exports.default = _default;
});