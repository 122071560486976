define("backalley/pods/main/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "goJ+Mhgw",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"site-header\"],false],[0,\"\\n\\n\"],[5,\"site-menu\",[],[[\"@loading\",\"@user\",\"@logout\"],[[24,[\"model\",\"loading\"]],[24,[\"model\",\"user\"]],[28,\"route-action\",[\"logout\"],null]]]],[0,\"\\n\\n\"],[7,\"main\",true],[10,\"class\",\"site-main\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"id\",\"site-overlay\"],[10,\"class\",\"site-overlay\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/template.hbs"
    }
  });

  _exports.default = _default;
});