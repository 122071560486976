define("backalley/pods/main/curate/orders/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lw082GPm",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"page-heading\",[],[[\"@fa\"],[\"file-alt\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    Curate\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.curate.orders\"]],{\"statements\":[[0,\"Orders\"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[1,[23,1,[\"order_number\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[28,\"page-title\",[[23,1,[\"order_number\"]]],null],false],[0,\"\\n\\n\"],[5,\"curate-order-form\",[],[[\"@order\"],[[23,1,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/curate/orders/edit/template.hbs"
    }
  });

  _exports.default = _default;
});