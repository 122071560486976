define("backalley/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R+kwj3GC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\",true],[10,\"class\",\"site-form\"],[8],[0,\"\\n\"],[4,\"page-heading\",null,null,{\"statements\":[[0,\"    \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"Login\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"form\",true],[10,\"class\",\"form-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Email\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"form-text\"],[8],[0,\"\\n        \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"envelope\"]]],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"class\",\"type\",\"name\",\"placeholder\",\"value\"],[\"input\",\"email\",\"email\",\"ben@streethub.com\",[24,[\"model\",\"user\",\"email\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Password\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"form-text\"],[8],[0,\"\\n        \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"lock\"]]],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"class\",\"type\",\"name\",\"placeholder\",\"value\"],[\"input\",\"password\",\"password\",\"😄😄😄😄😄😄😄😄😄\",[24,[\"model\",\"user\",\"password\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"form-submit\",null,[[\"id\",\"action\"],[\"form-submit\",[28,\"route-action\",[\"login\",[24,[\"model\",\"params\"]],[24,[\"model\",\"user\"]]],null]]],{\"statements\":[[0,\"      Login\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/login/template.hbs"
    }
  });

  _exports.default = _default;
});