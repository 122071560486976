define("backalley/pods/main/quotes/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hfP5aitu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-heading\",null,[[\"fa\"],[\"cubes\"]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"main.quotes\"]],{\"statements\":[[0,\"Quotes\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    Edit quote\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"page-title\",[\"Edit quote\"],null],false],[0,\"\\n\\n\"],[5,\"quote-form\",[],[[\"@quote\",\"@action\"],[[22,\"model\"],[28,\"route-action\",[\"update\",[24,[\"model\"]]],null]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/quotes/edit/template.hbs"
    }
  });

  _exports.default = _default;
});