define("backalley/pods/main/prospective-boutique/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ev9TkwSD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"status\"]],\"awaiting\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"prospective-boutique-form\",[],[[\"@pageTitle\",\"@prospectiveBoutique\",\"@showAdditionalInfo\",\"@saveAction\",\"@cancelAction\",\"@triggerEmail\"],[\"Edit\",[22,\"model\"],true,[28,\"route-action\",[\"save\"],null],[28,\"route-action\",[\"cancel\"],null],[28,\"route-action\",[\"triggerEmail\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"prospective-boutique\",[],[[\"@prospectiveBoutique\",\"@cancelAction\"],[[22,\"model\"],[28,\"route-action\",[\"cancel\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/prospective-boutique/edit/template.hbs"
    }
  });

  _exports.default = _default;
});