define("backalley/pods/main/product-delivery-tags/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s0s/UGU+",
    "block": "{\"symbols\":[],\"statements\":[[5,\"page-heading\",[],[[\"@fa\"],[\"tags\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.product-delivery-tags\"]],{\"statements\":[[0,\"Product Delivery Tags\"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    New Product Delivery Tag\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[28,\"page-title\",[\"Product Delivery Tags\"],null],false],[0,\"\\n\"],[1,[28,\"page-title\",[\"New\"],null],false],[0,\"\\n\\n\"],[5,\"product-delivery-tag-form\",[],[[\"@productDeliveryTag\",\"@onSave\"],[[23,0,[\"model\"]],[28,\"route-action\",[\"create\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/product-delivery-tags/new/template.hbs"
    }
  });

  _exports.default = _default;
});