define("backalley/pods/main/packaging/categories/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WLTocl0B",
    "block": "{\"symbols\":[],\"statements\":[[5,\"page-heading\",[],[[\"@fa\"],[\"box-open\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    Packaging\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.packaging.categories\"]],{\"statements\":[[0,\"Categories\"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    New\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[1,[28,\"page-title\",[\"New\"],null],false],[0,\"\\n\\n\"],[5,\"packaging-category-form\",[],[[\"@category\"],[[22,\"model\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/packaging/categories/new/template.hbs"
    }
  });

  _exports.default = _default;
});