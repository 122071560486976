define("backalley/pods/components/site-menu/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8p/EW1yw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"link\",\"external\"]],true],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[10,\"target\",\"_blank\"],[11,\"href\",[24,[\"link\",\"route\"]]],[8],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[[24,[\"link\",\"icon\"]]]]],[0,\" \"],[1,[24,[\"link\",\"label\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[[24,[\"link\",\"route\"]],[24,[\"link\",\"query\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"fa-icon\",[[12,\"class\",\"fa\"]],[[\"@icon\"],[[24,[\"link\",\"icon\"]]]]],[0,\"\\n  \"],[1,[24,[\"link\",\"label\"]],false],[0,\"\\n  \"],[5,\"fa-icon\",[[12,\"class\",[29,[\"fa \",[28,\"if\",[[24,[\"isFavourite\"]],\"fa-star-on\",\"fa-star-off\"],null]]]],[3,\"action\",[[23,0,[]],\"toggleFavourite\"],[[\"bubbles\"],[false]]]],[[\"@icon\"],[\"star\"]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/site-menu/link/template.hbs"
    }
  });

  _exports.default = _default;
});