define("backalley/pods/components/curate-brand-tag-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TpzcRfGU",
    "block": "{\"symbols\":[\"option\",\"@curateBrandTag\",\"@isEdit\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-container curate-brand-tag-form\"],[8],[0,\"\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Status\"],[9],[0,\"\\n      \"],[5,\"power-select\",[],[[\"@selected\",\"@options\",\"@onChange\"],[[23,2,[\"status\"]],[23,0,[\"curateBrandTagStatusOptions\"]],[28,\"fn\",[[28,\"mut\",[[23,2,[\"status\"]]],null]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"humanize\",[[23,1,[]]],null],false],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Name\"],[9],[0,\"\\n    \"],[5,\"form-text\",[],[[\"@value\",\"@action\"],[[23,2,[\"name\"]],[28,\"fn\",[[28,\"mut\",[[23,2,[\"name\"]]],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Display Order\"],[9],[0,\"\\n      \"],[5,\"form-text\",[],[[\"@type\",\"@value\",\"@action\"],[\"number\",[23,2,[\"displayOrder\"]],[28,\"fn\",[[28,\"mut\",[[23,2,[\"displayOrder\"]]],null]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n  \"],[5,\"form-submit\",[],[[\"@action\"],[[23,0,[\"save\"]]]],{\"statements\":[[0,\"\\n    Save\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/curate-brand-tag-form/template.hbs"
    }
  });

  _exports.default = _default;
});