define("backalley/pods/components/zone-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+yYqy+Lq",
    "block": "{\"symbols\":[\"country\"],\"statements\":[[7,\"form\",true],[10,\"class\",\"form-container\"],[10,\"style\",\"max-width: 400px\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Zone Name\"],[9],[0,\"\\n    \"],[1,[28,\"form-text\",null,[[\"autofocus\",\"error\",\"placeholder\",\"value\",\"action\"],[true,[24,[\"zone\",\"errors\",\"name\"]],\"Ex: Spain\",[24,[\"zone\",\"name\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"zone\",\"name\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"label\"],[8],[0,\"Add countries to this zone\"],[9],[0,\"\\n\"],[0,\"    \"],[1,[28,\"auto-complete\",null,[[\"search\",\"select\",\"content\",\"placeholder\",\"key\"],[[28,\"action\",[[23,0,[]],\"searchCountries\"],null],[28,\"action\",[[23,0,[]],\"addCountry\",[24,[\"zone\"]]],null],[24,[\"countryResults\"]],\"Search for a country\",\"name\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"ul\",true],[10,\"class\",\"collection-tags\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"zone\",\"countries\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[0,\"      \"],[7,\"li\",true],[10,\"class\",\"tag\"],[8],[0,\"\\n        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n        \"],[7,\"a\",false],[3,\"action\",[[23,0,[]],\"removeCountry\",[24,[\"zone\"]],[23,1,[]]]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\\n\"],[4,\"form-submit\",null,[[\"action\",\"secondaryAction\",\"secondary\",\"danger\",\"id\"],[[28,\"action\",[[23,0,[]],[24,[\"action\"]],[24,[\"zone\"]]],null],[24,[\"goBack\"]],\"Cancel\",\"Delete Zone\",\"form-submit\"]],{\"statements\":[[0,\"    Save\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/zone-form/template.hbs"
    }
  });

  _exports.default = _default;
});